import Choices from 'choices.js';

document.addEventListener('turbolinks:load', function() {
  const elements = document.querySelectorAll('.js-choice');
  if (elements.length) {
    let index = 0;
    const choice = [];
    for (var input of elements) {
      choice[index] = new Choices(input, {
        removeItemButton: true,
        addItemText: value => {
          return `Presiona enter para agregar <b>"${value}"</b>`;
        },
      });
      index++;
    }
  }
});
