import Pickr from '@simonwep/pickr';

function copyValue(value, array) {
  for (var inptColor of array) {
    inptColor.value = value;
  }
}

function colorPick(clname, defaultC) {
  return Pickr.create({
    el: clname,
    theme: 'nano',
    comparison: false,
    default: defaultC || '#000000',
    swatches: [
      '#010101',
      '#7ECFD7',
      '#8049B8',
      '#DE5B54',
      '#FFBE00',
      '#A7A9AB',
      '#303185',
      '#E21327',
      '#017F52',
      '#E01B50',
      '#F8ED12',
      '#027FC9',
      '#AA06DF',
      '#224253',
    ],
    
    components: {
      // Main components
      preview: true,
      opacity: false,
      hue: true,

      // Input / output Options
      interaction: {
        input: true,
      },
    },
  });
}
function setPrimary(colorHex, textFr){
  [...textFr].forEach(text => {
    text.style.color = colorHex;
  });
}
function setSecondary(colorHex, titleFr, inpFr, buttonFr, arrowFr){
  titleFr.style.color = colorHex;
  [...inpFr].forEach(inp => {
    inp.style.borderColor = `${colorHex}61`;
  });
  [...buttonFr].forEach(btn => {
    btn.style.backgroundColor = colorHex;
    btn.style.borderColor = colorHex;
  });
  [...arrowFr].forEach(btn => {
    btn.style.color = colorHex;
  });
}
document.addEventListener('turbolinks:load', function() {
  var balls = document.getElementsByClassName('eye-b');
  if (balls.length) {
    var colorBall = document.querySelector('.eye-b[data-color]').dataset.color,
      colorEyes = document.querySelector('.eye-h[data-color]').dataset.color,
      eyes = document.getElementsByClassName('eye-h'),
      bodyColor = document.querySelector('.qr-body-color'),
      defColor = bodyColor.dataset.color;

    const pickr = colorPick('.color-picker', colorBall);
    const pickr2 = colorPick('.color-picker2', colorEyes);
    const pickr3 = colorPick('.color-picker3', defColor);

    pickr.on('change', color => {
      let hexColor = color.toHEXA().toString();
      copyValue(hexColor, balls);
    });
    pickr2.on('change', color => {
      let hexColor = color.toHEXA().toString();
      copyValue(hexColor, eyes);
    });
    pickr3.on('change', color => {
      let hexColor = color.toHEXA().toString();
      bodyColor.value = hexColor;
    });
  }

  var pryColorForm = document.querySelector('.primary-form');
  if(pryColorForm){
    var secondColorForm = document.querySelector('.secondary-form'),
        colorPry = pryColorForm.dataset.color,
        colorsecond = secondColorForm.dataset.color;

    const pickrPry = colorPick('.color-picke-primary', colorPry),
          pickrSecond = colorPick('.color-picker-secondary', colorsecond);
    
    //form preview
    let textFr= document.getElementsByClassName("js-primary-fr"),
        buttonFr = document.getElementsByClassName("sheet-ejm__button"),
        arrowFr = document.getElementsByClassName("js-arrow-fr"),
        titleFr = document.querySelector("#sheet-title"),
        inpFr = document.getElementsByClassName("js-input-color")
        
        
        setPrimary(pryColorForm.dataset.color, textFr)
        setSecondary(secondColorForm.dataset.color, titleFr, inpFr, buttonFr, arrowFr)

    pickrPry.on('change', color => {
      let colorHex = color.toHEXA().toString()
      pryColorForm.value = colorHex;
      setPrimary(colorHex, textFr)
    });
    pickrSecond.on('change', color => {
      let colorHex = color.toHEXA().toString()
      secondColorForm.value = colorHex;
      setSecondary(colorHex, titleFr, inpFr, buttonFr, arrowFr)
    });

   
  
  }
});
