require('chart.js/dist/Chart');

function dynamicColors() {
  var r = Math.floor(Math.random() * 255);
  var g = Math.floor(Math.random() * 255);
  var b = Math.floor(Math.random() * 255);
  return 'rgba(' + r + ',' + g + ',' + b + ', 0.75)';
}

function chartBar(element, data = [0, 0, 0], labelName = [''], datasets, title = "", type="bar") {
  ctx = element.getContext('2d');
  let chart = new Chart(ctx, {
    // The type of chart we want to create
    type: type,

    // The data for our dataset
    data: {
      labels: labelName,
      datasets: datasets,
    },
    options: {
      title: {
        display: true,
        text: title
      },
      scales: {
        xAxes: [{}],
        yAxes: [
          {
            ticks: {
              suggestedMin: 0, // minimum will be 0, unless there is a lower value.
              // OR //
              beginAtZero: true, // minimum value will be 0.
            },
          },
        ],
      },
    },
  });
}

document.addEventListener('turbolinks:load', function () {
  let chartElement = document.getElementById('myChart');
  // var timeframe = chartbar.data("group-by");
  let data;
  if (chartElement) {
    let info = document.getElementById('bar-chart1').dataset.visits,
      visit = JSON.parse(info),
      xlabels= [''],
      labels = visit[0],
      data = visit[1].map(Number => parseInt(Number)),
      datasets = [];
    
    data.map((data, idx) => {
      datasets.push({
        label: `${labels[idx]}`,
        backgroundColor: dynamicColors(),
        borderColor: 'rgba(200, 200, 200, 0.75)',
        hoverBorderColor: 'rgba(200, 200, 200, 1)',
        barPercentage: 0.5,
        data: [data],
      });
    });

    chartBar(chartElement, data, xlabels, datasets, "", "bar");
  }
  // today bar
  let chartElement2 = document.getElementById('myChartToday');
  if (chartElement2) {
    let info = document.getElementById('bar-chart2').dataset.visits,
        infoDate = document.getElementById('bar-chart2').dataset.dates,
        visit = JSON.parse(info),
        xLabels = JSON.parse(infoDate),
        labels=[],
        data=[] ,
        datasets = [];
      for(let key in visit){
        labels.push(key);
        data.push(visit[key]);
      };
    
    data.map((data, idx) => {
      let theColor = dynamicColors()

      datasets.push({
        label: `${labels[idx]}`,
        backgroundColor: theColor,
        borderColor: theColor,
        hoverBorderColor: 'rgba(200, 200, 200, 1)',
        barPercentage: 1,
        data: data,
        fill: false
      });
    });

      chartBar(chartElement2, data, xLabels, datasets, "", "line");
    }
});

// function chartBar(element, data = [0, 0, 0], labelName = ['--', '--', '--'], datasets) {
//   ctx = element.getContext('2d');
//   let labels = labelName;
//   var chart = new Chart(ctx, {
//     // The type of chart we want to create
//     type: 'bar',

//     // The data for our dataset
//     data: {
//       labels: ['scans', 'conv', 'ctr%'],
//       datasets: datasets,
//     },
//     options: {
//       scales: {
//         xAxes: [{}],
//         yAxes: [
//           {
//             ticks: {
//               suggestedMin: 0, // minimum will be 0, unless there is a lower value.
//               // OR //
//               beginAtZero: true, // minimum value will be 0.
//             },
//           },
//         ],
//       },
//     },
//   });
// }

// document.addEventListener('turbolinks:load', function () {
//   let chartElement = document.getElementById('myChart');
//   // var timeframe = chartbar.data("group-by");
//   let data;
//   if (chartElement) {
//     let info = document.getElementById('bar-chart1').dataset.visits,
//       visit = JSON.parse(info),
//       labels = ["amc", "blobal", "otro"],
//       data = [[10, 20, 30],[40,50,60],[70,80,90]],
//       datasets = [];
//       data.map((data, idx) => {
//         console.log(data)
//         datasets.push({
//           label: `${labels[idx]}`,
//           backgroundColor: dynamicColors(),
//           borderColor: 'rgba(200, 200, 200, 0.75)',
//           hoverBorderColor: 'rgba(200, 200, 200, 1)',
//           barPercentage: 0.5,
//           data: data,
//         });
//       });
//       var x =[["amc", "blobal"], [[amc_scam, amc_conv, amc_ctr], [ [amc_scam, amc_conv, amc_ctr], [global_scam, global_conv, global_ctr] ]]
//       console.log(datasets)
//       chartBar(chartElement, data, labels, datasets);
//   }
// });