
$( document ).on('turbolinks:load', function() {
  $('.valid').keyup(function() {
      
      var isEmpty = false;
      $('.valid').each(function() {
          if ($(this).val() == '') {
              isEmpty = true;
              console.log("cambio de vacio")
          }
      });

      if (isEmpty) {
          console.log("vacio")
          $('#form-vits').attr('disabled', 'disabled');
      } else {
        console.log(" no vacio")
          $('#form-vits').removeAttr('disabled');
      }
  });
});