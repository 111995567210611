$( document ).on('turbolinks:load', function() {
  


  var content =  document.getElementsByClassName("mm-dropdown");
  
  Array.from(content).forEach( el => {

      let items = el.querySelectorAll('li');
      el.querySelector(".textfirst").addEventListener('click', function(){
        let active = document.querySelector(".mm-dropdown ul.active");
        if(active){
          active.classList.remove("active");
        }
        if(el.querySelector("ul").classList.contains("active")){
          el.querySelector("ul").classList.remove("active");
        }else{
          el.querySelector("ul").classList.add("active");
        }
      })
      for (let li of items){
     
        li.addEventListener('click', function(){
          el.querySelector("ul").classList.remove("active");
          el.querySelector(".textfirst").innerHTML = this.innerHTML;
          el.querySelector('.option').value = this.dataset.value;
        })
      }
    
  })

  // // Set
  // var main = $('div.mm-dropdown .textfirst'),
  //   container =$('div.mm-dropdown' ),
  //   li = $('div.mm-dropdown > ul > li.input-option'),
  //   ul = $('div.mm-dropdown > ul'),
  //   inputoption = $('div.mm-dropdown .option');

  // // Animation
  // container.click(function () {
  //   container.find('ul').toggle('fast');
  // });

  // Insert Data
  // li.click(function () {
  //   // hide
  //   ul.toggle('fast');
  //   var livalue = $(this).data('value');
  //   var lihtml = $(this).html();
  //   main.html(lihtml);
  //   inputoption.val(livalue);
  // });
});
