import flatpickr from 'flatpickr';

function allowUncheck(e) {
  if (this.previous) {
    this.checked = false;
  }

  document.querySelectorAll('.js-radius').forEach(elem => {
    elem.previous = elem.checked;
  });
}

function download(urls) {
  var url = urls.pop();

  var a = document.createElement('a');
  a.setAttribute('href', url);
  a.setAttribute('download', '');
  a.setAttribute('target', '_blank');
  a.click();
}

function propagateClick(element) {
  var buttons = element.closest('.content__item').getElementsByClassName('qr-link__download');
  var urls = [];

  [...buttons].forEach(element => {
    urls.push(element.href);
  });

  var interval = setInterval(function () {
    if (urls.length == 0) {
      clearInterval(interval);
    } else {
      download(urls);
    }
  }, 300);
}

function startSpinner(theform) {
  let btnSpinner = theform.querySelector('.js-spinner');
  btnSpinner.disabled = true;
  btnSpinner.querySelector('.js-spinner-hide').classList.add('d-none');
  btnSpinner.querySelector('.js-spinner-show').classList.remove('d-none');
}

// function spinerOnSubmit(form){
//   if(form){
    
//     form.addEventListener('submit', function (ev) {
//       ev.preventDefault;
//       let btnSpinner = form.querySelector('.js-spinner');
//       btnSpinner.disabled = true;
//       btnSpinner.querySelector('.js-spinner-hide').classList.add('d-none');
//       btnSpinner.querySelector('.js-spinner-show').classList.remove('d-none');
//     });
//   }
// } 

document.addEventListener('turbolinks:load', function () {
  // spinner on submit (repetitivo mejorar)
  let fromVariant = document.getElementById('new-link-vrn');
  if (fromVariant) {
    fromVariant.addEventListener('submit', function (ev) {
      ev.preventDefault;
      startSpinner(fromVariant);
    });
  }
  let fromfield = document.getElementById('new-field-form');
  if (fromfield) {
    fromfield.addEventListener('submit', function (ev) {
      ev.preventDefault;
      startSpinner(fromfield);
    });
  }

  let fromTemplateNew = document.getElementById('new-template');
  if (fromTemplateNew) {
    fromTemplateNew.addEventListener('submit', function (ev) {
      ev.preventDefault;
      startSpinner(fromTemplateNew);
    });
  }
  let fromChannelNew = document.getElementById('new-channel');
  if (fromChannelNew) {
    fromChannelNew.addEventListener('submit', function (ev) {
      ev.preventDefault;
      startSpinner(fromChannelNew);
    });
  }
  let fromCampagnNew = document.getElementById('new-campaign');
  if (fromCampagnNew) {
    fromCampagnNew.addEventListener('submit', function (ev) {
      ev.preventDefault;
      startSpinner(fromCampagnNew);
    });
  }
  let fromLinkNew = document.getElementById('new-link');
  if (fromLinkNew) {
    fromLinkNew.addEventListener('submit', function (ev) {
      ev.preventDefault;
      startSpinner(fromLinkNew);
    });
  }

  // tooltip if trucate text

  let elements = document.getElementsByClassName("over-tooltip-js");
  [...elements].forEach(element => {
    if (element.offsetWidth < element.scrollWidth){
      element.setAttribute("title", element.textContent)
    }
  });

  // png jpj only
    $('input[type=file]').change(function () {
        var val = $(this).val().toLowerCase(),
            regex = new RegExp("(.*?)\.(png|jpg)$");

        if (!(regex.test(val))) {
            $(this).val('');
            alert('solo formatos .png y .jpg');
        }
    });
  //modal
  let linkOpen = document.getElementById('open-m')
  if (linkOpen){
    let modalTerm = document.getElementById('modal-1'),
      closeM = document.querySelector(".modal-close"),
      closeOver = document.querySelector(".modal-overlay");
    
      linkOpen.addEventListener('click', function(){
        modalTerm.classList.add('active');
    })
      closeM.addEventListener('click', function(){
        modalTerm.classList.remove('active');
    })
      closeOver.addEventListener('click', function(){
        modalTerm.classList.remove('active');
    })

  }
        
  // redirect
  let linkOpenMsj = document.getElementsByClassName('open-msj')
  if (linkOpenMsj.length){
    let divMsj = document.getElementById("msg-redirect");
    let eventDone = false;
    let spanCounter = document.querySelector(".js-couter");
    let countSeg = 4;
    [...linkOpenMsj].forEach(btn => {
      btn.addEventListener("click", function(e){
        if (eventDone == true){
          eventDone = false;
          return
        }

        divMsj.classList.remove('d-none')
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
        
        var timer = setInterval(function() {
        
          if (countSeg === 0){
            clearInterval(timer);
            eventDone = true;
            btn.click();
          }
          spanCounter.innerHTML = countSeg
          countSeg--
        }, 1000);
      })
    })
  }
  
  
  // disable button uncheked Terms
  let chekTerm = document.getElementById("Term")
  if(chekTerm){
    let diableBtns = document.getElementsByClassName("js-unchecked")
    chekTerm.addEventListener('change', function(){
      if (chekTerm.checked){
        [...diableBtns].forEach(btn => {
          btn.classList.remove('disable')
        })
      }else{
        [...diableBtns].forEach(btn => {
          btn.classList.add('disable')
        })
      }
    })
  }

  // select color picker
  var selectEye = document.getElementById('js-color-eye') || 0;
  if (selectEye.length) {
    var eyes = document.getElementsByClassName('eye-h');
    selectEye.addEventListener(
      'change',
      function () {
        copyValue(this.value, eyes);
      },
      false
    );
  }
  
  var selectBall = document.getElementById('js-color-ball') || 0;
  if (selectBall.length) {
    var balls = document.getElementsByClassName('eye-b');
    selectBall.addEventListener(
      'change',
      function () {
        copyValue(this.value, balls);
      },
      false
    );
  }

  //agregar geolink
  let geoLink = document.getElementById("geolink-js")
  if(geoLink){
    let form = document.getElementById("geo-form");
    
      geoLink.addEventListener("click", function () {
        form.submit();
      });
  }
  // remove hide select
  let  impotanCheck = document.getElementById("link_additional_data_true")
  
  if(impotanCheck){
    let checks = document.getElementsByClassName("js-radius"),
      contentInp = document.getElementById("select-form-js"),
      spanInput = contentInp.getElementsByTagName("span")[0],
      linkInput = document.getElementById("link_link_forms_form_id")
      

    if (impotanCheck.checked){
      contentInp.classList.remove("v-hidden")
      spanInput.classList.remove("d-none")
      linkInput.required = true;
    }

    [...checks].forEach(radio => {
      radio.addEventListener("click", function(){
        setTimeout(function(){
          if (impotanCheck.checked){
            contentInp.classList.remove("v-hidden")
            spanInput.classList.remove("d-none")
            linkInput.required = true;
          }else{
            contentInp.classList.add("v-hidden")
            spanInput.classList.add("d-none")
            linkInput.required = false;
          }
        },100)
      })
    })
    
  }
  // donwload all qr
  var downloadBtn = document.getElementsByClassName('js-btn-download') || 0;
  if (downloadBtn) {
    Array.from(downloadBtn).forEach(element => {
      element.addEventListener(
        'click',
        function () {
          propagateClick(this);
        },
        false
      );
    });
  }
  // uncheck radio button
  if (document.getElementsByClassName('js-radio-uncheck').length) {
    document.querySelectorAll('.js-radius').forEach(elem => {
      elem.addEventListener('click', allowUncheck);
      elem.previous = elem.checked;
    });
  }

  //rangepicker
  let pickrCalendar = document.getElementById('range_date')
  if (pickrCalendar) {
    let dateRange = pickrCalendar.dataset.date.split("to") || "";
    let exportView = pickrCalendar.dataset.exportView;
    var maxLimitDate = new Date();
    
    flatpickr('#range_date', {
      mode: 'range',
      dateFormat: 'Y-m-d',
      minDate: setMinLimitDate(exportView),
      maxDate: maxLimitDate,
      defaultDate: dateRange,
      locale: {
        firstDayOfWeek: 1,
        weekdays: {
          shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
          longhand: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        },
        months: {
          shorthand: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Оct', 'Nov', 'Dic'],
          longhand: [
            'Enero',
            'Febrero',
            'Мarzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre',
          ],
        },
      },
    });
  }
  //endrangepicker
});

function setMinLimitDate(exportView) {
  if (exportView === 'true') {
    return null;
  }else{
    var min_limit_date = new Date();
    return min_limit_date.setMonth(min_limit_date.getMonth() - 2);
  };
};

window.readImgURL = function (input) {
  if (input.files && input.files[0]) {
    var reader = new FileReader();

    reader.onload = function (e) {
      $('#preview-img').attr('src', e.target.result);
    };

    reader.readAsDataURL(input.files[0]);
  }
};
